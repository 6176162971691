import Vue from 'vue';
import Router from 'vue-router';
import LoginPage from '@/components/LoginPage.vue';
import ActivityCenter from '@/components/ActivityCenter.vue';
import ConfirmActivity from '@/components/ConfirmActivity.vue';
import GroupsPage from '@/components/GroupsPage.vue';
import ManageActivities from '@/components/ManageActivities.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    { path: '/', name: 'Login', component: LoginPage },
    { path: '/activity-center', name: 'ActivityCenter', component: ActivityCenter },
    { path: '/groups', name: 'GroupsPage', component: GroupsPage },
    { path: '/activities', name: 'ManageActivities', component: ManageActivities },
    { path: '/confirm-activity', name: 'ConfirmActivity', component: ConfirmActivity },
  ],
});

function isAuthenticated() {
  return !!localStorage.getItem('authToken');
}

router.beforeEach((to, from, next) => {
  if (to.name === 'ConfirmActivity' || to.name === 'Login') {
    next();
  } else if (!isAuthenticated()) {
    next({ name: 'Login' });
  } else {
    next();
  }
});

export default router;
