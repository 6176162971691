<template>
  <v-container fluid>
    <v-row class="mb-4">
      <v-col cols="12" class="d-flex align-center justify-space-between">
        <div class="d-flex align-center">
          <v-img
            src="@/assets/logo.png"
            max-width="140"
            class="mr-3 cursor-pointer"
            @click="navigateToActivityCenter"
          ></v-img>
          <h2 class="text-h5 font-weight-medium">Activities Management</h2>
        </div>
        <div class="d-flex">
          <v-btn color="primary" class="mr-2" @click="$router.push('/activity-center')">
            Activity Center
          </v-btn>
          <v-btn color="primary" class="mr-2" @click="$router.push('/groups')">
            Groups
          </v-btn>
          <v-btn color="primary" outlined>
            Activities
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-card class="pa-6 mb-4" elevation="2">
      <v-row>
        <v-col cols="12">
          <v-card class="pa-4" outlined>
            <v-toolbar flat color="transparent">
              <v-toolbar-title>Activity Groups</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchGroups"
                append-icon="mdi-magnify"
                label="Search Groups"
                single-line
                hide-details
                class="mr-4"
              ></v-text-field>
              <v-btn color="primary" @click="openGroupDialog">Add Group</v-btn>
            </v-toolbar>
            <v-skeleton-loader
              v-if="isLoadingGroups"
              type="table"
              :loading="isLoadingGroups"
              class="mx-auto"
            ></v-skeleton-loader>
            <v-data-table
              v-else
              :headers="groupHeaders"
              :items="filteredGroups"
              :items-per-page="itemsPerPage"
              :page.sync="currentPage"
              :footer-props="{
                'items-per-page-options': [5, 10, 15],
                'items-per-page-text': 'Groups per page',
              }"
              class="elevation-0"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn icon @click="editGroup(item)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon @click="confirmDeleteGroup(item)">
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card class="pa-4" outlined>
            <v-toolbar flat color="transparent">
              <v-toolbar-title>All Activities</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchActivities"
                append-icon="mdi-magnify"
                label="Search Activities"
                single-line
                hide-details
                class="mr-4"
              ></v-text-field>
              <v-btn color="primary" @click="openActivityDialog">Add Activity</v-btn>
            </v-toolbar>
            <v-skeleton-loader
              v-if="isLoadingActivities"
              type="table"
              :loading="isLoadingActivities"
              class="mx-auto"
            ></v-skeleton-loader>
            <v-data-table
              v-else
              :headers="activityHeaders"
              :items="filteredActivities"
              :items-per-page="itemsPerPage"
              :page.sync="currentPage"
              :footer-props="{
                'items-per-page-options': [5, 10, 15],
                'items-per-page-text': 'Activities per page',
              }"
              class="elevation-0"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn icon @click="editActivity(item)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon @click="confirmDeleteActivity(item)">
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog v-model="groupDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{
            editingGroup ? "Edit Group" : "Add Group"
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-text-field
              label="Group Name"
              v-model="groupForm.name"
              :rules="[rules.required]"
            ></v-text-field>
            <v-select
              v-model="groupForm.activities"
              :items="allActivities"
              item-text="name"
              item-value="id"
              label="Select Activities"
              multiple
              chips
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeGroupDialog">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="saveGroup" :loading="isLoading">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="activityDialog" max-width="400px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{
            editingActivity ? "Edit Activity" : "Add Activity"
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="activityForm">
            <v-text-field
              label="Activity Name"
              v-model="activityForm.name"
              :rules="[rules.required]"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeActivityDialog">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="saveActivity" :loading="isLoading">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" max-width="400px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{
            deleteType === "group" ? "Delete Group" : "Delete Activity"
          }}</span>
        </v-card-title>
        <v-card-text>
          Are you sure you want to delete the
          {{ deleteType === "group" ? "group" : "activity" }} "{{
            itemToDelete ? itemToDelete.name : ""
          }}"?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="closeDeleteDialog">Cancel</v-btn>
          <v-btn color="red darken-1" text @click="deleteItem" :loading="isLoading">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "ActivitiesManagementPage",
  data() {
    return {
      resp: null,
      groupDialog: false,
      activityDialog: false,
      deleteDialog: false,
      editingGroup: false,
      editingActivity: false,
      itemToDelete: null,
      deleteType: "",
      searchGroups: "",
      searchActivities: "",
      currentPage: 1,
      itemsPerPage: 5,
      groupForm: {
        name: "",
        activities: [],
      },
      activityForm: {
        name: "",
      },
      groups: [],
      allActivities: [],
      isLoadingGroups: true,
      isLoadingActivities: true,
      isLoading: false,
      activityHeaders: [
        { text: "Activity Name", value: "name" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      groupHeaders: [
        { text: "Group Name", value: "name" },
        { text: "Number of Activities", value: "activities.length" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  computed: {
    filteredGroups() {
      return this.groups.filter((group) =>
        group.name.toLowerCase().includes(this.searchGroups.toLowerCase())
      );
    },
    filteredActivities() {
      return this.allActivities.filter((activity) =>
        activity.name
          .toLowerCase()
          .includes(this.searchActivities.toLowerCase())
      );
    },
  },
  methods: {
    async fetchGroups() {
      this.isLoadingGroups = true;
      try {
        const response = await fetch(
          "https://activity-tracker-backend-production.up.railway.app/api/get-activities-groups/"
        );
        if (response.ok) {
          this.groups = await response.json();
        } else {
          console.error("Failed to fetch groups.");
        }
      } catch (error) {
        console.error("Error fetching groups:", error);
      } finally {
        this.isLoadingGroups = false;
      }
    },
    async fetchActivities() {
      this.isLoadingActivities = true;
      try {
        const response = await fetch(
          "https://activity-tracker-backend-production.up.railway.app/api/get-activities/"
        );
        if (response.ok) {
          this.allActivities = await response.json();
        } else {
          console.error("Failed to fetch activities.");
        }
      } catch (error) {
        console.error("Error fetching activities:", error);
      } finally {
        this.isLoadingActivities = false;
      }
    },
    navigateToActivityCenter() {
      this.$router.push({ path: "/activity-center" });
    },
    openGroupDialog() {
      this.groupForm = { name: "", activities: [] };
      this.editingGroup = false;
      this.groupDialog = true;
    },
    closeGroupDialog() {
      this.groupDialog = false;
    },
    openActivityDialog() {
      this.activityForm = { name: "" };
      this.editingActivity = false;
      this.activityDialog = true;
    },
    closeActivityDialog() {
      this.activityDialog = false;
    },
    confirmDeleteGroup(group) {
      this.itemToDelete = group;
      this.deleteType = "group";
      this.deleteDialog = true;
    },
    confirmDeleteActivity(activity) {
      this.itemToDelete = activity;
      this.deleteType = "activity";
      this.deleteDialog = true;
    },
    closeDeleteDialog() {
      this.deleteDialog = false;
    },
    async deleteItem() {
      if (!this.itemToDelete) return;
      this.isLoading = true;
      const url =
        this.deleteType === "group"
          ? `https://activity-tracker-backend-production.up.railway.app/api/delete-activity-group/`
          : `https://activity-tracker-backend-production.up.railway.app/api/delete-activity/`;

      try {
        const response = await fetch(url, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id: this.itemToDelete.id }),
        });

        if (response.ok) {
          this.fetchGroups();
          this.fetchActivities();
          this.closeDeleteDialog();
        } else {
          console.error("Failed to delete item.");
        }
      } catch (error) {
        console.error("Error deleting item:", error);
      } finally {
        this.isLoading = false;
      }
    },
    async saveGroup() {
      this.isLoading = true;
      const payload = {
        id: this.groupForm.id,
        name: this.groupForm.name,
        activities: this.groupForm.activities,
      };

      const url = this.editingGroup
        ? `https://activity-tracker-backend-production.up.railway.app/api/update-activity-group/`
        : `https://activity-tracker-backend-production.up.railway.app/api/post-activity-group/`;

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        if (response.ok) {
          this.fetchGroups();
          this.closeGroupDialog();
        } else {
          console.error("Failed to save group.");
        }
      } catch (error) {
        console.error("Error saving group:", error);
      } finally {
        this.isLoading = false;
      }
    },
    async saveActivity() {
      this.isLoading = true;
      const payload = {
        id: this.activityForm.id,
        name: this.activityForm.name,
      };

      const url = this.editingActivity
        ? `https://activity-tracker-backend-production.up.railway.app/api/update-activity/`
        : `https://activity-tracker-backend-production.up.railway.app/api/post-activity/`;

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        if (response.ok) {
          this.fetchActivities();
          this.closeActivityDialog();
        } else {
          console.error("Failed to save activity.");
        }
      } catch (error) {
        console.error("Error saving activity:", error);
      } finally {
        this.isLoading = false;
      }
    },
    editGroup(group) {
      this.groupForm = {
        id: group.id,
        name: group.name,
        activities: group.activities.map((activity) => activity.id),
      };
      this.editingGroup = true;
      this.groupDialog = true;
    },
    editActivity(activity) {
      this.activityForm = {
        id: activity.id,
        name: activity.name,
      };
      this.editingActivity = true;
      this.activityDialog = true;
    },
  },
  mounted() {
    this.fetchGroups();
    this.fetchActivities();
  },
};
</script>

<style scoped>
.v-card {
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.v-dialog__content {
  background-color: rgba(255, 255, 255, 0) !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.v-dialog .v-card {
  background-color: #ffffff !important;
}

.v-dialog {
  background-color: transparent !important;
}

.v-card-title,
.v-card-text,
.v-card-actions {
  background-color: #ffffff;
}

.v-toolbar-title {
  font-weight: bold;
  color: #000956;
}

.v-data-table {
  background-color: #ffffff;
}

.v-data-table-header {
  font-weight: bold;
}

.v-text-field {
  max-width: 300px;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
