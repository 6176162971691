<template>
  <v-container class="d-flex justify-center align-center fill-height" fluid>
    <v-card class="pa-12" max-width="500" elevation="2">
      <v-card-title class="justify-center">
        <h1 class="text-h4 font-weight-bold">Activity Tracker</h1>
      </v-card-title>
      <v-card-text class="text-center">
        <v-img
          src="@/assets/logo.png"
          max-width="200"
          class="mb-10 mt-5 mx-auto d-block"
        ></v-img>
        <v-form v-model="valid">
          <v-text-field
            label="User"
            v-model="username"
            class="input-field mb-5"
            hide-details
            outlined
            dense
          ></v-text-field>
          <v-text-field
            label="Password"
            v-model="password"
            class="input-field mb-10"
            hide-details
            outlined
            dense
            type="password"
          ></v-text-field>
          <v-btn
            :disabled="!valid || is_loading"
            class="button-primary mt-5"
            large
            block
            @click="login"
            elevation="2"
          >
            <v-progress-circular
              v-if="is_loading"
              indeterminate
              color="white"
              size="24"
            ></v-progress-circular>
            <span v-else>LOGIN</span>
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      valid: true,
      username: "",
      password: "",
      is_loading: false,
    };
  },
  methods: {
    async login() {
      this.is_loading = true;
      try {
        const response = await fetch(
          "https://activity-tracker-backend-production.up.railway.app/api/login/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              username: this.username,
              password: this.password,
            }),
            credentials: "include",
          }
        );

        if (response.ok) {
          const data = await response.json();
          localStorage.setItem("authToken", data.token);
          this.$router.push({ name: "ActivityCenter" });
        } else {
          alert("Invalid credentials");
        }
      } catch (error) {
        console.error("Error during login:", error);
        alert("An error occurred. Please try again.");
      } finally {
        this.is_loading = false;
      }
    },
  },
};
</script>

<style scoped>
.input-field >>> .v-input__control .v-input__slot::before {
  border-color: #000000 !important;
}

.input-field >>> .v-label {
  color: #000000 !important; /* Persistent label color */
}

.input-field >>> .v-label.v-label--active {
  color: #000000 !important; /* Label color on focus */
}

.button-primary {
  background-color: #ffffff;
  color: #000000;
  border-radius: 25px;
  transition: background-color 0.3s, color 0.3s;
}

.button-primary:hover {
  background-color: #000000;
  color: #ffffff;
}

.v-card {
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
</style>
