<template>
  <v-container fluid>
    <v-row class="mb-4">
      <v-col cols="12" class="d-flex align-center">
        <v-img src="@/assets/logo.png" max-width="120" class="mr-3"></v-img>
        <h2 class="text-h5 font-weight-medium">Confirm Activity</h2>
      </v-col>
    </v-row>

    <v-card class="pa-6 mb-4" elevation="2">
      <v-container fluid>
        <v-row class="mt-6">
          <v-col cols="12">
            <v-text-field
              v-model="fileName"
              label="File Name"
              outlined
              class="no-focus-effect"
              readonly
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="projectName"
              label="Project Name"
              outlined
              class="no-focus-effect"
              readonly
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="emailAddress"
              label="Your Email Address"
              outlined
              class="no-focus-effect"
              readonly
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="groupName"
              label="Group Name"
              outlined
              class="no-focus-effect"
              readonly
            ></v-text-field>
          </v-col>

          <v-container class="d-flex flex-row flex-wrap justify-start mb-4">
            <v-col
              cols="14"
              md="4"
              lg="6"
              v-for="(group, groupIndex) in groupedActivities"
              :key="groupIndex"
            >
              <v-card class="pa-4 mb-4" outlined>
                <v-row>
                  <v-col cols="12">
                    <h3 class="text-h6 font-weight-bold">
                      {{ group.groupName }}
                    </h3>
                  </v-col>

                  <v-col cols="12">
                    <v-row>
                      <v-col
                        cols="12"
                        v-for="(activity, activityIndex) in group.activities"
                        :key="activityIndex"
                        class="d-flex align-center mb-2"
                      >
                        <v-switch
                          v-model="activity.checked"
                          :label="activity.label"
                          :disabled="activity.disabled"
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-container>

          <v-col cols="12" class="d-flex justify-end">
            <v-btn
              large
              color="primary"
              @click="openConfirmDialog"
              :disabled="submitDisabled"
              >Submit</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-dialog v-model="confirmDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Confirm Activities</span>
        </v-card-title>
        <v-card-text>
          Are you sure you want to confirm the following activities for the file
          "{{ fileName }}"?
          <ul>
            <li v-for="activity in selectedActivitiesNames" :key="activity.id">
              {{ activity.name }}
            </li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="closeConfirmDialog"
            >Cancel</v-btn
          >
          <v-btn color="primary" text @click="submitForm">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" :timeout="3000" :color="snackbar.color">
      {{ snackbar.message }}
      <v-btn color="white" text @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      fileName: "",
      emailAddress: "",
      groupName: "",
      projectName: "",
      activities: [],
      groupedActivities: [],
      id: null,
      confirmDialog: false,
      snackbar: {
        show: false,
        message: "",
        color: "",
      },
      submitDisabled: false,
    };
  },
  created() {
    this.extractParams();
    this.fetchActivitiesGroups();
  },
  computed: {
    selectedActivities() {
      return this.groupedActivities
        .flatMap((group) => group.activities)
        .filter((activity) => activity.checked)
        .map((activity) => activity.id);
    },
    selectedActivitiesNames() {
      return this.groupedActivities
        .flatMap((group) => group.activities)
        .filter((activity) => activity.checked)
        .map((activity) => ({ id: activity.id, name: activity.label }));
    },
  },
  methods: {
    extractParams() {
      const urlParams = new URLSearchParams(window.location.search);
      this.fileName = urlParams.get("file_name");
      this.projectName = urlParams.get("project_name");
      this.id = urlParams.get("id");
      this.groupName = urlParams.get("group_name");
      this.emailAddress = urlParams.get("email_address");

      if (this.fileName) this.fileName = decodeURIComponent(this.fileName);
      if (this.projectName)
        this.projectName = decodeURIComponent(this.projectName);
      if (this.groupName) this.groupName = decodeURIComponent(this.groupName);
      if (this.emailAddress)
        this.emailAddress = decodeURIComponent(this.emailAddress);
    },
    async fetchActivitiesGroups() {
      try {
        const response = await fetch(
          "https://activity-tracker-backend-production.up.railway.app/api/get-activities-groups/"
        );
        if (response.ok) {
          const data = await response.json();

          this.groupedActivities = data
            .map((group) => ({
              groupName: group.name,
              activities: group.activities.map((activity) => ({
                id: activity.id,
                label: activity.name,
                checked: false,
                disabled: group.name !== this.groupName,
              })),
              disabled: group.name !== this.groupName,
            }))
            .filter((group) => group.activities.length > 0);
        } else {
          console.error("Failed to fetch activity groups.");
        }
      } catch (error) {
        console.error("Error fetching activity groups:", error);
      }
    },
    openConfirmDialog() {
      if (this.selectedActivities.length === 0) {
        this.showSnackbar(
          "Please select at least one activity to confirm.",
          "error"
        );
        return;
      }
      this.confirmDialog = true;
    },
    closeConfirmDialog() {
      this.confirmDialog = false;
    },
    async submitForm() {
      this.confirmDialog = false;
      const postData = {
        id: this.id,
        fileName: this.fileName,
        projectName: this.projectName,
        groupName: this.groupName,
        emailAddress: this.emailAddress,
        confirmed: true,
        activities: this.selectedActivities,
      };

      try {
        const response = await fetch(
          "https://activity-tracker-backend-production.up.railway.app/api/activity-confirm/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(postData),
          }
        );

        const result = await response.json();

        if (response.ok) {
          this.showSnackbar("Activity confirmed successfully!", "success");
          this.submitDisabled = true;
        } else {
          console.error("Error result:", result);
          this.showSnackbar(`Error: ${result.message}`, "error");
        }
      } catch (error) {
        console.error("Error during submission:", error);
        this.showSnackbar("An unexpected error occurred.", "error");
      }
    },
    showSnackbar(message, color) {
      this.snackbar.message = message;
      this.snackbar.color = color;
      this.snackbar.show = true;
    },
  },
};
</script>
